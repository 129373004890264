[data-amplify-authenticator] {
    --amplify-components-authenticator-router-box-shadow: 0 0 16px var(--amplify-colors-overlay-10);
    --amplify-components-authenticator-router-box-shadow: 0 0 16px var(--amplify-colors-overlay-10);
    --amplify-components-authenticator-router-border-width: 0;
    --amplify-components-authenticator-form-padding: var(--amplify-space-medium) var(--amplify-space-xl) var(--amplify-space-xl);
    --amplify-components-button-primary-background-color: var(--amplify-colors-neutral-100);
    --amplify-components-fieldcontrol-focus-box-shadow: 0 0 0 2px var(--amplify-colors-purple-60);
    --amplify-components-tabs-item-active-border-color: var(--amplify-colors-neutral-100);
    --amplify-components-tabs-item-color: var(--amplify-colors-neutral-80);
    --amplify-components-tabs-item-active-color: var(--amplify-colors-purple-100);
    --amplify-components-button-link-color: var(--amplify-colors-purple-80);
    --amplify-components-authenticator-width: 100%; /* Example width, adjust as needed */
    --amplify-components-authenticator-max-width: 90%; /* Ensures responsiveness */
    --amplify-components-authenticator-height: 100%; /* Adjust height as needed, or use 'auto' */
    --amplify-components-authenticator-padding: 20px; /* Adjust height as needed, or use 'auto' */
  }

[data-amplify-router] {
    width: var(--amplify-components-authenticator-width);
    max-width: var(--amplify-components-authenticator-max-width);
    height: var(--amplify-components-authenticator-height);
}

[amplify-tabs] {
    width: var(--amplify-components-authenticator-width);
    max-width: var(--amplify-components-authenticator-max-width);
    height: var(--amplify-components-authenticator-height);
}

[data-amplify-router] .amplify-tabs {
    width: var(--amplify-components-authenticator-width);
    max-width: var(--amplify-components-authenticator-max-width);
    height: var(--amplify-components-authenticator-height);
}

[data-amplify-authenticator] .amplify-button,
[data-amplify-authenticator] .amplify-field-group__control,
[data-amplify-authenticator] .amplify-label {
    font-size: 1.2rem; /* Increase font size */
    padding: 0px 10px; /* Increase padding for larger appearance */
}

[data-amplify-authenticator] .amplify-input {
    font-size: 1.1rem; /* Larger text in inputs */
    padding: 10px; /* Larger padding for inputs */
}

.auth-wrapper {
    display: flex;
    justify-content: center;
    align-items: center; /* This centers the Authenticator vertically as well */
    height: 100vh; /* Adjust as needed to fit your design */
    padding: 10px;
}
