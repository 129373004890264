*{
  box-sizing: border-box;
}

html {
  height: 100%;
  font-size: 62.5%;
  margin: 0;
  justify-content: center;
  align-items: center;
}

body {
  height: 100%;
  font: 1.6rem Arial, sans-serif;
  margin: 0;
  justify-content: center;
  align-items: center;
  
}



a{
  color:black;
  text-decoration: none;
}
a:hover{
  color:#f0a040
}

header {
  grid-area: header;
  background-color: #203040;
  color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items:center;
  padding: 0.5rem;
}

header a {
  color: #ffffff;
  font-weight: bold;
  text-decoration: none;
}

header a:hover {
  color: #f0a040

}
.brand a{
  font-size: 3rem;
}

footer {
  background-color: #203040;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
}

.grid-container {
  display: flex;
  flex-direction: column;
  grid-template-areas: 
  'header'
  'main'
  'footer';
  grid-template-columns: 1fr;
  grid-template-rows: 5rem 1fr 5rem;
  height: 100%;
  
}


/* Properties */

.properties {
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
  padding: 0;
  margin: 0;
  justify-content: center;
}

.properties li {
  flex: 0 1 30rem;
  margin: 1rem;
  margin-bottom: 0;
  height: 42rem;
  
  
}

.property {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  width: 300px;
  max-height: 350px;
  border: 2px solid #8b8b8b; /* Light border for the input */
  border-radius: 12px;
  box-shadow: 3px 3px 1px #8fa9ff;
}

.property-image {
  width: 100%; /* Makes the image as wide as the container */
  height: 200px; /* Adjust height as needed */
  object-fit: cover; /* Ensures the image covers the area, change to 'contain' if you don't want it cropped */
  margin-top: 0;
  border-radius: 10px 10px 0 0;
}

.property-info {
  padding:10px
}

.property-name {
  line-height: 2.2rem;
  font-size: 20px;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.property-city {
  line-height: 2.2rem;
  font-size: 1.2rem;
  color:black

}

.property-price {
  line-height: 3.2rem;
  font-size: 2rem;
}

.cluster-points-list {
  max-height: 200px;
  overflow: auto;
}

/* Home */ 
.HomeTitle {
  text-align: center;
  margin-top: 20px;
}

.hero-header {
  text-align: center;
  
  height: 350px;
  padding: 50px; /* Top and Bottom Padding */
 
}

.hero-header h1 {
  margin-bottom: 10px; /* Space below the heading */
  font-size: 2.5em; /* Larger text for the heading */
}

.hero-header p {
  margin-bottom: 20px; /* Space below the paragraph */
  font-size: 1.2em; /* Slightly larger text for the paragraph */
}

#search-input {
  width: 60%; /* Width of the search input */
  padding: 10px; /* Padding inside the search input */
  border: 1px solid #ddd; /* Light border for the input */
  border-radius: 5px; /* Rounded corners for the input */
  margin-right: 10px; /* Space between input and button */
}

#search-button {
  padding: 10px 20px; /* Padding inside the button */
  background-color: #007bff; /* Background color for the button */
  color: white; /* Text color for the button */
  border: none; /* No border for the button */
  border-radius: 5px; /* Rounded corners for the button */
  cursor: pointer; /* Cursor changes to a pointer when hovering over the button */
}

#search-button:hover {
  background-color: #0056b3; /* Darker background on hover */
}

@media (max-width: 768px) {
  .hero-header {
      padding: 30px 10px;
  }

  #search-input {
      width: 100%; /* Full width on smaller screens */
      margin-bottom: 10px; /* Space below the input */
  }

  #search-button {
      width: 100%; /* Full width on smaller screens */
  }
}

main {
  flex: 1;
  /* background: linear-gradient(#ffffff, rgb(185, 221, 255)) */
}

.carousel-image {
  display: flex;
  justify-content: center;
  align-items: center;
  
}

.carousel-image img {
  width: 1000px; /* Adjust as needed */
  height: 600px; /* Adjust as needed */
  object-fit: cover; /* This will crop the image to fill the area. Use 'contain' to avoid cropping. */
}

.single_property {
  padding: 50px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column; /* Stack the children vertically */
}

.single_property img {
  height: 600px;
  width: 1.2h;
  justify-content: left;
}

.property-desc {
  padding:2px;
}
/* Add additional styling for your header, main and footer */

.image-carousel {
  position: fixed; /* Fixed position */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8); /* Semi-transparent background */
  z-index: 1000; /* High z-index to be on top of other content */
  display: flex;
  justify-content: center;
  align-items: center;
}
.close-carousel {
  position: absolute;
  top: 20px;
  right: 20px;
  border: none;
  background: none;
  color: white;
  font-size: 30px;
  cursor: pointer;
}

.carousel-image img {
  position: relative;
  width: 1000px; /* Adjust as needed */
  height: 600px; /* Adjust as needed */
  border-radius: 10px;
  margin-top:150px;
}

.carousel-image.active {
  display: flex; /* Ensure the active image is displayed */
  justify-content: center;
  width: 100%; /* Ensure image container takes full width of the carousel */
}

@media (max-width: 1000px) {
  .image-carousel, .image-carousel img {
      width: 80vw;
      max-height: 400px;
  }
}

/* Styles for very small screens */
@media (max-width: 500px) {
  .image-carousel, .image-carousel img {
      width: 100vw;
      max-height: 300px;
  }
}
#carousel-prev, #carousel-next {
  position: absolute;
  top: 50%; /* Center vertically */
  transform: translateY(-50%);
  background-color: transparent; /* Semi-transparent background */
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  z-index: 10; /* Ensure they are above the image */
}

#carousel-prev {
  left: 10px; /* Space from the left edge */
}

#carousel-next {
  right: 10px; /* Space from the right edge */
}

.image-carousel::before {
  left: 0;
}

.image-carousel::after {
  right: 0;
}

.gallery-container {
  display: grid;
  grid-template-columns: 1fr 1fr; /* Two equal columns */
  width: 78%; /* Adjust as needed */
  height: 500px; /* Adjust height as needed */
  gap: 10px; /* Optional space between images */
  align-items: center;
  justify-content: center;
  margin: 0 auto; /* Auto margin for horizontal centering */
  padding: 20px;
}

.large-image-container {
  grid-column: 1; /* Large image takes the first column */
  display: flex;
  justify-content: center;
  align-items: center;
  height:520px;
  padding-top: 10px
}

.small-images-container {
  position: relative; /* Set positioning context */
  grid-column: 2; /* Small images take the second column */
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Two columns */
  grid-template-rows: repeat(2, 1fr); /* Two rows */
  gap: 10px; /* Space between images */
  justify-content: center;
  align-items: center;
  height:inherit
}
.large-image-container img {
  width: 100%; /* Fill the width of the container */
  height: 100%; /* Fill the height of the container */
  object-fit: cover; /* Ensure the entire image fits within the container */
  object-position: center; /* Center the image within the container */
  border-radius: 10px;
}
.small-images-container img {
  width: 100%; /* Fill the width of the container */
  height: 250px; /* Fill the height of the container */
  object-fit: cover; /* Ensure the entire image fits within the container */
  object-position: center; /* Center the image within the container */
  border-radius: 10px;
}
#see-all-pictures {
  position: absolute; /* Absolute positioning */
  bottom: 10px; /* 10px from the bottom of the container */
  right: 10px; /* 10px from the right of the container */
  padding: 5px 10px; /* Adjust padding as needed */
  background-color: #469fff; /* Background color */
  color: white; /* Text color */
  border: none; /* No border */
  cursor: pointer; /* Pointer cursor on hover */
  border-radius: 10px;
  border: 2px solid #ffffff;
  /* Additional styling as needed */
}
.property-details {
  
  /* grid-template-columns: repeat(2, 1fr); /* 2 columns of equal width */
  /* grid-gap: 10px; Space between columns  */
  column-count: 2;
}


.property-and-contact-container {
  display: flex;
  flex-wrap: wrap; /* This allows items to wrap to the next line if there's not enough space */
  justify-content: space-between;
  align-items: flex-start; /* Align the tops of the items if they have different heights */
  gap: 10px; /* This adds space between the flex items */
  width: 80%;
}

.property-details-container {
  flex: 1; /* Take up the left side */
  display: flex;
  flex-direction: column;
  gap: 10px; /* This adds space between the flex items */
  width: 80%;
 
  margin: 0 auto
  /* Styles for the container (padding, margin, etc.) */
}

.property-card, .contact-container {
  flex: 1; /* Each child will take up an equal amount of space */
  min-width: 0; /* Prevent flex items from growing too large */
  box-sizing: border-box; /* Include padding and border in the element's total width */
  
}

.contact-container {
  max-width: 30%; /* This ensures that the contact form does not exceed 40% of the parent container's width */
  width: 80%;
  padding: 20px; /* Add padding if needed */
  border: 1px solid #ccc;
  display: flex;
  flex-direction: column;
  gap: 10px; /* This adds space between the flex items */
  margin: 0 auto;
  align-items: center;
}

.property-card {
  max-width: 100%; /* This ensures that the property card does not exceed 60% of the parent container's width */
  padding: 20px; /* Add padding if needed */
  border: 1px solid #ccc;
}
.contact-container form {
  display: flex;
  flex-direction: column;
  gap: 10px; /* Add some space between form elements */
}

.contact-right {
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 20px;
  padding-right: 50px;
}
.contact-title {
  font-weight: 600;
  font-size: 32px;
  margin-bottom: 5px;
  width: 100%
}

.contact-title hr {
  background-color: black;
  border:none;
  width:120px;
  height:5px;
  margin-bottom: 20px;
  border-radius:10px;
}
.contact-inputs {
  width: 100%;

  max-width: 250px;
  height: 50px;
  border:1px solid #ccc;
  outline:none;
  padding-left:25px;
  font-weight: 500;
  border-radius: 20px;
}




.property-header h2.property-title {
  font-size: 1.5em;
  margin-bottom: 5px;
}

.property-header .property-location,
.property-header .property-type {
  color: #666;
  margin-bottom: 5px;
}

.property-details {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
}

.property-detail {
  text-align: center;
}
.property-detail {
  display: flex;
  align-items: center; /* Aligns the image and text vertically */
  padding-left:30px;
  padding-right:30px;
}

.property-detail img {
  width: 24px; /* Adjust as needed */
  height: auto;
  margin-bottom: 5px;
  margin-right: 5px;
}

.property-pricing {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #ccc;
  padding-top: 10px;
}

.property-pricing .property-price .price,
.property-pricing .property-estimate .estimate {
  font-weight: bold;
}

/* Media query for smaller screens */
@media (max-width: 600px) {
  .property-details {
      flex-direction: column;
  }
  
  .property-pricing {
      flex-direction: column;
  }
  
  .property-pricing > div {
      margin-bottom: 10px;
  }
}

.column {
  float: left;
  width: 50%;
}

.column span {
  display: block; /* This makes each span start on a new line */
  margin-bottom: 10px; /* Optional: Adds some space below each span */
}


.data-amplify-container {
  min-width: 600px !important;
  min-height: 600px !important;
}

